.inventory-window {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 1rem;
    margin: 2rem;
}

.inventory-window code {
    background: lemonchiffon;
    color: darkgoldenrod;
    padding: 0.2rem;
}

.inventory-window label {
    line-height: 2rem;
}

.inventory-window-title-bar {
    width: 100%;
    display: inline-block;
    /* background: lightgray; */
    height: 2.5rem;
}

.inventory-window-title-bar .title {
    font-weight: bold;
    display: inline-block;
    width: 70%;
}

.inventory-window-title-bar .title>span {
    padding-left: 1.5rem;
    position: relative;
    top: 0.2rem;
}

.inventory-window-title-bar .window-controls {
    display: inline-block;
    width: 30%;
    text-align: right;
}


.inventory-item-holder>div{
    display: inline-block;
    vertical-align: middle;
}

.inventory-item-name-holder {
    width: 70%;
    overflow: hidden;
}

.inventory-item-controls-holder {
    width: 30%;
    text-align: right;
    visibility: hidden;
}

.inventory-item-holder:hover .inventory-item-controls-holder {
    visibility: visible;
}