.inventory-board-manager a {
    text-decoration: none;
    font-weight: bold;
}

.inventory-board-manager a, .inventory-board-manager a * {
    color: tomato;
    stroke: tomato;
}

.inventory-board-manager a:hover, .inventory-board-manager a:hover * {
    color: orangered;
    stroke: orangered;
}

.inventory-board-manager .warning {
    border: 1px solid seagreen;
    color: seagreen;
    background: palegreen;
    padding: 1rem 2rem;
    margin: 2rem;
}

.board-list-header {
    margin-top: 3rem;
}

.board-list {
    text-align: center;
}

.board-single {
    /* border: 1px solid gray; */
    padding: 1rem 0.5rem;
    margin: 1rem 0;
}

.board-uri {
    margin-top: 1.2rem;
    font-size: 0.8rem;
}

.board-uri>button {
    background: ghostwhite;
    margin: 0; padding: 0.4rem 2rem;
    border: none;
    font-size: 1rem;
    font-family: monospace;
    
}

.copy-icon {
    display: inline-block;
    margin-left: 1rem;
}

.board-uri button, .copy-icon * {
    color: darkslategray;
    stroke: darkslategray;;
}