
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;700&display=swap');




@font-face {
    font-family: 'enchanted_landregular';
    src: url('./fonts/enchanted_land-webfont.woff2') format('woff2'),
         url('./fonts/enchanted_land-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

* {
  font-family: "IBM Plex Sans";
}

.app-header {
  height: 9rem;
  background-image:url('./img/header.jpg');
  background-position-y:100%;
  text-align: center;
  padding-top: 1rem;
}

.app-header>span {
  display:block;
}

.app-header>.app-title {
  font-family: 'enchanted_landregular';
  font-size: 5rem;
  /* font-weight: bold; */
  color: white;
}

.app-header>.app-subtitle {
  font-style: italic;
  color: lightgray;
}

nav {
  display: inline-block;
  width: 100%;
  height: 3rem;
  background: black;
  margin-bottom: 3rem;
}

nav ul {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  margin-top: 0.9rem;
}

nav ul li {
  display: inline;
  list-style: none;
  padding: 0 2rem;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.app-body {
  text-align: center;
}