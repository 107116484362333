@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
    font-family: 'enchanted_landregular';
    src: url(/static/media/enchanted_land-webfont.c0c8d42b.woff2) format('woff2'),
         url(/static/media/enchanted_land-webfont.ab6eb75a.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}

* {
  font-family: "IBM Plex Sans";
}

.app-header {
  height: 9rem;
  background-image:url(/static/media/header.df2d4eaa.jpg);
  background-position-y:100%;
  text-align: center;
  padding-top: 1rem;
}

.app-header>span {
  display:block;
}

.app-header>.app-title {
  font-family: 'enchanted_landregular';
  font-size: 5rem;
  /* font-weight: bold; */
  color: white;
}

.app-header>.app-subtitle {
  font-style: italic;
  color: lightgray;
}

nav {
  display: inline-block;
  width: 100%;
  height: 3rem;
  background: black;
  margin-bottom: 3rem;
}

nav ul {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  text-align: center;
  margin-top: 0.9rem;
}

nav ul li {
  display: inline;
  list-style: none;
  padding: 0 2rem;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.app-body {
  text-align: center;
}
.viewport-header {
    margin-bottom: 2rem;
}

.inventory-window {
    margin-bottom: 1rem;
}
.inventory-window {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 1rem;
    margin: 2rem;
}

.inventory-window code {
    background: lemonchiffon;
    color: darkgoldenrod;
    padding: 0.2rem;
}

.inventory-window label {
    line-height: 2rem;
}

.inventory-window-title-bar {
    width: 100%;
    display: inline-block;
    /* background: lightgray; */
    height: 2.5rem;
}

.inventory-window-title-bar .title {
    font-weight: bold;
    display: inline-block;
    width: 70%;
}

.inventory-window-title-bar .title>span {
    padding-left: 1.5rem;
    position: relative;
    top: 0.2rem;
}

.inventory-window-title-bar .window-controls {
    display: inline-block;
    width: 30%;
    text-align: right;
}


.inventory-item-holder>div{
    display: inline-block;
    vertical-align: middle;
}

.inventory-item-name-holder {
    width: 70%;
    overflow: hidden;
}

.inventory-item-controls-holder {
    width: 30%;
    text-align: right;
    visibility: hidden;
}

.inventory-item-holder:hover .inventory-item-controls-holder {
    visibility: visible;
}
.inventory-item {
    border: 1px solid moccasin;
    border-radius: 3px;
    background: lightyellow;
    margin: 0.5rem;
    font-size: 0.9rem;
}

.inventory-item-title, .inventory-item-description {
    padding-left: 1.2rem;
    color: saddlebrown;
}

.inventory-item-title {
    background: moccasin;
    font-weight: bold;
    color: saddlebrown;

}

.item-description-label {
    font-size: 0.8rem;
    color: goldenrod;
    padding-top: 0.3rem;
}
.item-control-button {
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 3px;
    background: white;
    margin-right: 0.9rem;
    padding: 0 0.2rem;
    font-size: 0.9rem;
}

.item-control-glyph {
    color: gray;
}

.item-control-icon {
    position: relative;
    top: 0.1rem;
}

.item-control-icon>* {
    stroke: gray;
    
    /* color: gray; */
}

.item-control-button:hover {
    cursor: pointer;
    background: pink;
    border-color: red;
}

.item-control-button:hover .item-control-glyph, .item-control-button:hover .item-control-icon>* {
    color: red;
    stroke: red;
}
.inventory-board-manager a {
    text-decoration: none;
    font-weight: bold;
}

.inventory-board-manager a, .inventory-board-manager a * {
    color: tomato;
    stroke: tomato;
}

.inventory-board-manager a:hover, .inventory-board-manager a:hover * {
    color: orangered;
    stroke: orangered;
}

.inventory-board-manager .warning {
    border: 1px solid seagreen;
    color: seagreen;
    background: palegreen;
    padding: 1rem 2rem;
    margin: 2rem;
}

.board-list-header {
    margin-top: 3rem;
}

.board-list {
    text-align: center;
}

.board-single {
    /* border: 1px solid gray; */
    padding: 1rem 0.5rem;
    margin: 1rem 0;
}

.board-uri {
    margin-top: 1.2rem;
    font-size: 0.8rem;
}

.board-uri>button {
    background: ghostwhite;
    margin: 0; padding: 0.4rem 2rem;
    border: none;
    font-size: 1rem;
    font-family: monospace;
    
}

.copy-icon {
    display: inline-block;
    margin-left: 1rem;
}

.board-uri button, .copy-icon * {
    color: darkslategray;
    stroke: darkslategray;;
}
#profile-page-holder {
    margin: 0 5rem;
}
