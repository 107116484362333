.inventory-item {
    border: 1px solid moccasin;
    border-radius: 3px;
    background: lightyellow;
    margin: 0.5rem;
    font-size: 0.9rem;
}

.inventory-item-title, .inventory-item-description {
    padding-left: 1.2rem;
    color: saddlebrown;
}

.inventory-item-title {
    background: moccasin;
    font-weight: bold;
    color: saddlebrown;

}

.item-description-label {
    font-size: 0.8rem;
    color: goldenrod;
    padding-top: 0.3rem;
}